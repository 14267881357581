import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import biyidp from "../images/biyid.png";
import axios from 'axios';

export default function Lightpages() {
  const navigate = useNavigate();
  const [lightPages, setLightPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null); // State for modal2Active data
  const [loading, setLoading] = useState(false); // Loading state for form submission

  const fetchLightPages = async () => {
    try {
      const token = 'your_token_here'; // Use your actual token
      const response = await axios.get('https://server.biyiaroloye.com/v1/api/getLightPage', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setLightPages(response.data.lightPage);
    } catch (error) {
      console.error('Error fetching Light Pages', error);
    }
  };

  useEffect(() => {
    fetchLightPages();
  }, []);

  const handleNavigate = (page) => navigate(`/${page}`);
  const [modalActive, setModalActive] = useState(false);
  const [modal2Active, setModal2Active] = useState(false);

  const handleAddContentClick = () => {
    setModalActive(true);
    setModal2Active(false); // Deactivate modal2 if active
  };

  const handlePCNameClick = (page) => {
    setSelectedPage(page); // Store selected page data
    setModal2Active(true);
    setModalActive(false); // Deactivate modal if active
  };

  const handleModalClick = (e) => {
    if (e.target.classList.contains('modal-bg') || e.target.classList.contains('close-btn-modal')) {
      setModalActive(false);
    }
  };

  const handleModal2Click = (e) => {
    if (e.target.classList.contains('modal2-bg') || e.target.classList.contains('close-btn-modal')) {
      setModal2Active(false);
    }
  };

  const [isActivedel, setIsActivedel] = useState(false);
  const [selectedPageId, setSelectedPageId] = useState(null); 
  const handleDeleteModal = async () => {
    try {
      setLoading(true); // Start loading when the delete process begins

      const token = localStorage.getItem('token');
      // Make the delete request to the API using the selected ID
      const response = await axios.delete(`https://server.biyiaroloye.com/v1/api/deleteLightPageById/${selectedPageId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (response.status === 200) {
        console.log('Delete successful', response.data);
    fetchLightPages();

      }
    } catch (error) {
      console.error('Error deleting content:', error);
    } finally {
      // Hide the modal and stop loading
      setLoading(false);
      setIsActivedel(false);
      setSelectedPageId(null);
    }
  };

  // Open modal and set the selected page ID
  const handleDeleteClick = (pageId) => {
    setSelectedPageId(pageId);
    setIsActivedel(true); // Open modal
  };

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [innerAlt, setInnerAlt] = useState('');
  const [image1, setImage1] = useState(null); // For first file input
  const [image2, setImage2] = useState(null); // For second file input
  const [message, setMessage] = useState('');

  const handleImage1Upload = (e) => setImage1(e.target.files[0]);
  const handleImage2Upload = (e) => setImage2(e.target.files[0]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when submitting

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    formData.append('innerAlt', innerAlt);
    if (image1) formData.append('images', image1); // Append first image
    if (image2) formData.append('images', image2); // Append second image

    try {
      const token = localStorage.getItem('token');

      const response = await axios.post('https://server.biyiaroloye.com/v1/api/createLightPage', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      setMessage(response.data.message);
      fetchLightPages(); // Refresh the LightPages list

      // Clear form and close modal only if submission is successful
      setTitle('');
      setContent('');
      setInnerAlt('');
      setImage1(null);
      setImage2(null);
      setModalActive(false);
    } catch (error) {
      setMessage('Error creating Light Page');
      console.error(error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const [searchTerm, setSearchTerm] = useState('');

const filteredLightPages = lightPages.filter(page =>
  page.title.toLowerCase().includes(searchTerm.toLowerCase())
);

  return (
    <div className='portfolio-page'>
      <div className="side-bar">
        <div className="dp-space" onClick={() => handleNavigate('Portfoliopage')}>
          <figure>
            <img src={biyidp} alt="" />
          </figure>
          <h3>Hello 'Biyi</h3>
        </div>
        <section>
          <div onClick={() => handleNavigate('Portfoliopage')}>
            Portfolio
          </div>
          <div className='sidebar-indicator' onClick={() => handleNavigate('Lightpages')}>Light Pages</div>
          <div onClick={() => handleNavigate('Products')}>Products</div>
          <div onClick={() => handleNavigate('Clients')}>Clients</div>
        </section>
      </div>
      <main>
        <div className="add-content-btn" onClick={handleAddContentClick}>
          + Add content
        </div>
        <input 
      type="search" 
      placeholder='Search content' 
      className='search-input' 
      value={searchTerm} 
      onChange={(e) => setSearchTerm(e.target.value)} 
    />
        <div className="portfolio-contents">
          {filteredLightPages.length > 0 ? (
            filteredLightPages.map((page) => (
              <div key={page._id} className="portfolio-content">
                <figure className='pc-image'>
                  <img src={page.image} alt={page.innerPage.alt} />
                </figure>
                <h4 className="pc-text-name" onClick={() => handlePCNameClick(page)}>{page.title}</h4>
                <span className="cancel-pc-item"  onClick={()=>handleDeleteClick(page._id)}>X</span>
              </div>
            ))
          ) : (
            <p>No Light Pages found</p>
          )}
        </div>
      </main>
      {modalActive && (
        <div className="modal-bg" onClick={handleModalClick}>
          <div className="portfoliopage-modal">
            <div className="close-btn-modal" onClick={() => setModalActive(false)}>
              x
            </div>
            <form onSubmit={handleFormSubmit} className="ppm-form">
              <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} required />
              <textarea placeholder="Content" value={content} onChange={(e) => setContent(e.target.value)} required></textarea>
              <input type="text" placeholder="Alt text" value={innerAlt} onChange={(e) => setInnerAlt(e.target.value)} required />
              <div className="file-section">
                <p>Upload image 1</p>
                <input type="file" onChange={handleImage1Upload} accept="image/*" required />
              </div>
              <div className="file-section">
                <p>Upload image 2</p>
                <input type="file" onChange={handleImage2Upload} accept="image/*" required />
              </div>
              <div className="form-btns">
                <button type="submit" disabled={loading}>
                  {loading ? 'Publishing...' : 'Publish'}
                </button>
              </div>
            </form>
            {message && <p>{message}</p>}
          </div>
        </div>
      )}
      {modal2Active && selectedPage && (
        <div className="modal2-bg" onClick={handleModal2Click}>
          <div className="portfoliopage-modal2">
            <div className="close-btn-modal" onClick={() => setModal2Active(false)}>
              x
            </div>
            <form action="" className="ppm-form2">
              <div className="form-subheading">
                <h2>Edit content</h2>
                <span className="fs-line"></span>
              </div>
              <section className="form-input-sec">
                <input type="text" placeholder='Edit Title' defaultValue={selectedPage.title} />
                <div className='file-section'>
                  <p>Upload new image/gif/video</p>
                  <input type="file" />
                </div>
                <input type="text" placeholder='Edit Alt text' defaultValue={selectedPage.innerPage.alt} />
              </section>
              <textarea name="" placeholder='Edit content' defaultValue={selectedPage.content}></textarea>
              <div className='file-section'>
                <p>Upload Content File</p>
                <input type="file" />
              </div>
              <div className="form-btns">
                <button>Edit</button>
                <button className="cancel-btn">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}
       <div className={`delete-modal-bg ${isActivedel ? 'active' : ''}`}>
        <h1>Sure you want to delete content?</h1>
        <div className="dm-btns">
          <span className='dm-btn1' onClick={handleDeleteModal}>
            {loading ? 'Deleting...' : 'Yes'}
          </span>
          <span className='dm-btn2' onClick={() => setIsActivedel(false)}>No</span>
        </div>
      </div>
    </div>
  );
}
