import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import biyidp from "../images/biyid.png";
import pcimage from "../images/Component 40.png";
import { v4 as uuidv4 } from 'uuid'; // Import uuid for generating unique IDs
import axios from "axios";

export default function Products() {
  const navigate = useNavigate();

  const handleNavigate = (page) => navigate(`/${page}`);

  const [modalActive, setModalActive] = useState(false);
  const [modal2Active, setModal2Active] = useState(false);
  const [isActivedel, setIsActivedel] = useState(false);
  const [fileSections, setFileSections] = useState([{ id: uuidv4() }]);
  const [productItems, setProductItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);


  const fetchPortfolio = async () => {
    axios.get('https://server.biyiaroloye.com/v1/api/getAllProductItems') // replace with your actual endpoint
      .then(response => {
        setProductItems(response.data.ProductItems);
        setFilteredItems(response.data.ProductItems);
      })
      .catch(error => {
        console.error('Error fetching portfolio items:', error);
      });
  }
  useEffect(() => {
    
      fetchPortfolio()
  }, []);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);

    const filtered = productItems.filter(item =>
      item.title.toLowerCase().includes(value)
    );
    setFilteredItems(filtered);
  };
  const addFileSection = () => {
    setFileSections([...fileSections, { id: uuidv4() }]);
  };

  const removeFileSection = (id) => {
    setFileSections(fileSections.filter(section => section.id !== id));
  };

  const handleAddContentClick = () => {
    setModalActive(true);
    setModal2Active(false); // Deactivate modal2 if active
  };

  const handlePCNameClick = () => {
    setModal2Active(true);
    setModalActive(false); // Deactivate modal if active
  };

  const handleModalClick = (e) => {
    if (e.target.classList.contains('close-btn-modal') || e.target.classList.contains('modal-bg')) {
      setModalActive(false);
    }
  };

  const handleModal2Click = (e) => {
    if (e.target.classList.contains('close-btn-modal') || e.target.classList.contains('modal2-bg')) {
      setModal2Active(false);
    }
  };

 


  const [formData, setFormData] = useState({
    title: '',
    type: '',
    content: '',
    headlines: {
      headlineTitle: '',
      headlineSubtitle: '',
      headlineButtonTitle: '',
      headlineButtonLink: ''
    },
    features: [{ featuresHeadlineText: '', featuresContent: '' }],
    moreFeatures: {
      moreFeaturesHeadlineText: '',
      moreFeaturesAltText: '',
      moreFeaturesSubtitle: '',
      moreFeaturesButtonLink: ''
    },
    testimony: [{ testimonyHeadlineText: '', testimonyContent: '' }],
    punch: { buttonTitle: '', buttonLink: '' },
    featuresImages: [],
    moreFeaturesImage: null,
    headlinesImage: null,
    image: null
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files });
  };

  const addFeature = () => {
    setFormData({ 
      ...formData, 
      features: [...formData.features, { featuresHeadlineText: '', featuresContent: '' }] 
    });
  };

  const addTestimony = () => {
    setFormData({ 
      ...formData, 
      testimony: [...formData.testimony, { testimonyHeadlineText: '', testimonyContent: '' }] 
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const data = new FormData();

    // Append text data
    data.append('title', formData.title);
    data.append('type', formData.type);
    data.append('content', formData.content);
    data.append('headlines', JSON.stringify(formData.headlines));
    data.append('features', JSON.stringify(formData.features));
    data.append('moreFeatures', JSON.stringify(formData.moreFeatures));
    data.append('testimony', JSON.stringify(formData.testimony));
    data.append('punch', JSON.stringify(formData.punch));

    // Append image files
    if (formData.featuresImages.length) {
      Array.from(formData.featuresImages).forEach((file) => {
        data.append('featuresImages', file);
      });
    }

    if (formData.moreFeaturesImage) {
      data.append('moreFeaturesMoreFeaturesImage', formData.moreFeaturesImage[0]);
    }

    if (formData.headlinesImage) {
      data.append('headlinesHeadlineImage', formData.headlinesImage[0]);
    }

    if (formData.image) {
      data.append('image', formData.image[0]);
    }
    console.log(formData);
    try {
      // const response = await axios.post('https://ziggy-backend-ffco.onrender.com/v1/api/createProduct', data, {
      //   headers: { 'Content-Type': 'multipart/form-data' }
      // });
      // console.log(response.data);
      // handle success (e.g., show a success message or reset form)
    } catch (err) {
      setError('Failed to create product. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  const [selectedPageId, setSelectedPageId] = useState(null); 
   // Open modal and set the selected page ID
   const handleDeleteClick = (pageId) => {
    setSelectedPageId(pageId);
    setIsActivedel(true); // Open modal
  };

  const handleDeleteModal = async () => {
    try {
      setLoading(true); // Start loading when the delete process begins

      const token = localStorage.getItem('token');
      // Make the delete request to the API using the selected ID
      const response = await axios.delete(`https://server.biyiaroloye.com/v1/api/deleteProductById/${selectedPageId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (response.status === 200) {
        console.log('Delete successful', response.data);
        fetchPortfolio();

      }
    } catch (error) {
      console.error('Error deleting content:', error);
    } finally {
      // Hide the modal and stop loading
      setLoading(false);
      setIsActivedel(false);
      setSelectedPageId(null);
    }
  };
  return (
    <div className='portfolio-page'>
      <div className="side-bar">
        <div className="dp-space" onClick={() => handleNavigate('Portfoliopage')}>
          <figure>
            <img src={biyidp} alt="" />
          </figure>
          <h3>Hello 'Biyi</h3>
        </div>
        <section>
          <div onClick={() => handleNavigate('Portfoliopage')}>
            Portfolio
          </div>
          <div onClick={() => handleNavigate('Lightpages')}>Light Pages</div>
          <div className='sidebar-indicator' onClick={() => handleNavigate('Products')}>Products</div>
          <div onClick={() => handleNavigate('Clients')}>Clients</div>
        </section>
      </div>
      <main>
        <div className="add-content-btn" onClick={handleAddContentClick}>
          + Add content
        </div>
        <input 
      type="search" 
      placeholder="Search content" 
      className="search-input" 
      value={searchText} 
      onChange={handleSearch} 
    />
        <div className="portfolio-contents">
         
          {/* Add more portfolio content */}
          {filteredItems.map(item => (
        <div className="portfolio-content" key={item._id}>
          <figure className="pc-image">
            <img src={item.image} alt={item.alt} />
          </figure>
          <h4 className="pc-text-name" onClick={() => handlePCNameClick(item)}>
            {item.title}
          </h4>
          <span className="cancel-pc-item" onClick={()=>handleDeleteClick(item._id)}>X</span>
        </div>
      ))}
        </div>
      </main>

      {modalActive && (
        <div className="modal-bg">
          <div className="portfoliopage-modal">
            <div className="close-btn-modal" onClick={handleModalClick}>
              x
            </div>
            <form onSubmit={handleSubmit} className='ppm-form'>
      {/* Outer Page Section */}
      <div className="form-subheading">
        <h2>Outer Page</h2>
        <span className="fs-line"></span>
      </div>
      <section className="form-input-sec">
        <input
          type="text"
          name="title"
          placeholder='Title'
          onChange={handleInputChange}
        />
        <div className='file-section'>
          <p>Upload Cover Visual</p>
          <input type="file" name="image" onChange={handleFileChange} />
        </div>
        <textarea
          name="content"
          placeholder='Add Text Content'
          onChange={handleInputChange}
        ></textarea>
        <input
          type="text"
          name="punch.buttonTitle"
          placeholder='Button title'
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="punch.buttonLink"
          placeholder='Button link'
          onChange={handleInputChange}
        />
        <select
          name="type"
          onChange={handleInputChange}
        >
          <option value="" disabled selected hidden>Product Type</option>
          <option value="All">All</option>
          <option value="Ebooks">Ebooks</option>
          <option value="Service as product">Service as product</option>
          <option value="Product solutions">Product solutions</option>
          <option value="Ecourses">Ecourses</option>
        </select>
      </section>
      <br /><br /><br />

      {/* Headline Section */}
      <div className="form-subheading">
        <h2>Headline</h2>
        <span className="fs-line"></span>
      </div>
      <section className="form-input-sec">
        <div className='file-section'>
          <p>Upload headline cover visual</p>
          <input type="file" name="headlinesImage" onChange={handleFileChange} />
        </div>
        <input
          type="text"
          name="headlines.headlineTitle"
          placeholder='Head title'
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="headlines.headlineSubtitle"
          placeholder='Sub title'
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="headlines.headlineButtonTitle"
          placeholder='Button title'
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="headlines.headlineButtonLink"
          placeholder='Button link'
          onChange={handleInputChange}
        />
      </section>

      {/* Features Section */}
      <div className="form-subheading">
        <h2>Features</h2>
        <span className="fs-line"></span>
      </div>
      <section className="form-input-sec">
        {formData.features.map((feature, index) => (
          <div key={index}>
            <input
              type="text"
              name={`features[${index}].featuresHeadlineText`}
              placeholder='Headline text'
              onChange={handleInputChange}
            />
            <input
              type="text"
              name={`features[${index}].featuresContent`}
              placeholder='Content'
              onChange={handleInputChange}
            />
          </div>
        ))}
        <button type="button" onClick={addFeature} id='btn-design'>Add Feature</button>
        <div className='file-section'>
          <p>Upload feature images</p>
          <input type="file" name="featuresImages" multiple onChange={handleFileChange} />
        </div>
      </section>

      {/* More Features Section */}
      <div className="form-subheading">
        <h2>More Features</h2>
        <span className="fs-line"></span>
      </div>
      <section className="form-input-sec">
        <input
          type="text"
          name="moreFeatures.moreFeaturesHeadlineText"
          placeholder='Headline text'
          onChange={handleInputChange}
        />
        <div className='file-section'>
          <p>Upload more features cover visual</p>
          <input type="file" name="moreFeaturesImage" onChange={handleFileChange} />
        </div>
        <input
          type="text"
          name="moreFeatures.moreFeaturesSubtitle"
          placeholder='Sub title'
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="moreFeatures.moreFeaturesButtonLink"
          placeholder='Button link'
          onChange={handleInputChange}
        />
      </section>

      {/* Testimonials Section */}
      <div className="form-subheading">
        <h2>Testimonials</h2>
        <span className="fs-line"></span>
      </div>
      <section className="form-input-sec">
        {formData.testimony.map((testimony, index) => (
          <div key={index}>
            <input
              type="text"
              name={`testimony[${index}].testimonyHeadlineText`}
              placeholder='Testimonial Author'
              onChange={handleInputChange}
            />
            <input
              type="text"
              name={`testimony[${index}].testimonyContent`}
              placeholder='Testimonial Quote'
              onChange={handleInputChange}
            />
          </div>
        ))}
        <button type="button" onClick={addTestimony} id='btn-design'>Add Testimony</button>
      </section>

      {/* Final Punch Section */}
      <div className="form-subheading">
        <h2>Final Punch</h2>
        <span className="fs-line"></span>
      </div>
      <section className="form-input-sec">
        <input
          type="text"
          name="punch.buttonTitle"
          placeholder='Button title'
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="punch.buttonLink"
          placeholder='Button link'
          onChange={handleInputChange}
        />
      </section>

      <button type="submit" disabled={loading}>
        {loading ? 'Publishing...' : 'Publish'}
      </button>
      {error && <p>{error}</p>}
    </form>
          </div>
        </div>
      )}
      
      {modal2Active && (
        <div className="modal2-bg" >
          <div className="portfoliopage-modal2">
            <div className="close-btn-modal" onClick={handleModal2Click}>
              x
            </div>
            <form action="" className="ppm-form2">
              <div className="form-subheading">
                <h2>Outer Page</h2>
                <span className="fs-line"></span>
              </div>
              <section className="form-input-sec">
                <input type="text" placeholder='Title'/>
                <div className='file-section'>
                  <p>Edit desktop cover visual</p>
                  <input type="file" />
                </div>
                <textarea name="" placeholder='Edit content' id=""></textarea>
                <input type="text" placeholder='Edit Button title'/>
                <input type="text" placeholder='Edit Button link'/>
                <select name="" id="">
                  <option value="" disabled hidden selected>Content type</option>
                  <option value="All">All</option>
                  <option value="Ebooks">Ebooks</option>
                  <option value="Service as product">Service as product</option>
                  <option value="Product solutions">Product solutions</option>
                  <option value="Ecourses">Ecourses</option>
                </select>
              </section><br /><br /><br />
              <div className="form-subheading">
                <h2>Headline</h2>
                <span className="fs-line"></span>
              </div>
              <section className="form-input-sec">
                <div className='file-section'>
                  <p>Edit headline cover visual</p>
                  <input type="file" />
                </div>
                <input type="text" placeholder='Edit Head title'/>
                <input type="text" placeholder='Edit Sub title'/>
                <input type="text" placeholder='Edit Button title'/>
                <input type="text" placeholder='Edit Button link'/>
              </section><br /><br />
              <div className="form-subheading">
                <h2>Features</h2>
                <span className="fs-line"></span>
              </div>
              <section className="form-input-sec">
                <input type="text" placeholder='Edit headline text'/>
                <section className='section-container'>
                  {fileSections.map(({ id }, index) => (
                    <div key={id} className='file-sec'>
                      <div className='file-section'>
                        <p>Edit image</p>
                        <input type="file" />
                      </div>
                      <input type="text" placeholder='Edit alt text'/>
                      <input type="text" placeholder='Edit content'/>
                      <div className="dlt-single-file-section" onClick={() => removeFileSection(id)}>
                        X
                      </div>
                    </div>
                  ))}
                </section>
              </section><br /><br />

              <div className="form-subheading">
                <h2>More features</h2>
                <span className="fs-line"></span>
              </div>
              <section className="form-input-sec">
                <input type="text" placeholder='Edit Headline Text'/>
                <div className='file-section'>
                  <p>Edit cover visual</p>
                  <input type="file" />
                </div>
                <input type="text" placeholder='Edit alt text'/>
                <input type="text" placeholder='Edit button title'/>
                <input type="text" placeholder='Edit button link'/>
              </section><br /><br /><br />
              <div className="form-subheading">
                <h2>Edit testimonials</h2>
                <span className="fs-line"></span>
              </div>
              <section className='section-container'>
                {fileSections.map(({ id }, index) => (
                  <div key={id} className='form-input-sec'>
                    <input type="text" placeholder='Edit Testimonial Author'/>
                    <input type="text" placeholder='Edit Testimonial Quote'/>
                    <div className="dlt-single-file-section" onClick={() => removeFileSection(id)}>
                      X
                    </div>
                  </div>
                ))}
              </section><br /><br />

              <div className="form-subheading">
                <h2>Edit final punch</h2>
                <span className="fs-line"></span>
              </div>
              <section className="form-input-sec">
                <input type="text" placeholder='Edit Button title'/>
                <input type="text" placeholder='Edit Button link'/>
              </section>
              <div className="form-btns">
                <button type="reset" onClick={() => setIsActivedel(true)}>Delete</button>
                <button type="submit">Update</button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className={`delete-modal-bg ${isActivedel ? 'active' : ''}`}>
        <h1>Sure you want to delete content?</h1>
        <div className="dm-btns">
          <span className='dm-btn1' onClick={handleDeleteModal}>
          {loading ? 'Deleting...' : 'Yes'}
          </span>
          <span className='dm-btn2' onClick={() => setIsActivedel(false)}>No</span>
        </div>
      </div>
    </div>
  );
}
