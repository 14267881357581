import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import biyidp from "../images/biyid.png";
import pcimage from "../images/Component 40.png";

export default function Clients() {
    const navigate = useNavigate();
    const [isModalActive, setIsModalActive] = useState(false);
    const [clients, setClients] = useState([]); // State to store API data
    const [selectedClient, setSelectedClient] = useState(null); // For modal content

    useEffect(() => {
        // Fetch data from API when the component mounts
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get("https://server.biyiaroloye.com/v1/api/getLetTalk", {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setClients(response.data.letsTalk);
            } catch (error) {
                console.error("Error fetching clients:", error);
            }
        };

        fetchData();
    }, []);

    const handleNavigate = (page) => navigate(`/${page}`);

    const handleRowClick = (client) => {
        setSelectedClient(client);
        setIsModalActive(true);
    };

    const handleCloseModal = () => {
        setIsModalActive(false);
        setSelectedClient(null);
    };

    return (
        <div className='portfolio-page'>
            <div className="side-bar">
                <div className="dp-space" onClick={() => handleNavigate('Portfoliopage')}>
                    <figure>
                        <img src={biyidp} alt="" />
                    </figure>
                    <h3>Hello 'Biyi</h3>
                </div>
                <section>
                    <div onClick={() => handleNavigate('Portfoliopage')}>Portfolio</div>
                    <div onClick={() => handleNavigate('Lightpages')}>Light Pages</div>
                    <div onClick={() => handleNavigate('Products')}>Products</div>
                    <div className='sidebar-indicator' onClick={() => handleNavigate('Clients')}>Clients</div>
                </section>
            </div>
            <main>
                <h1>Clients</h1>
                <div className="table-space">
                    <table>
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>EMAIL</th>
                                <th>PAGE SOURCE</th>
                                <th>TIME</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clients.map((client, index) => (
                                <tr key={client._id} onClick={() => handleRowClick(client)}>
                                    <td>{index + 1}</td>
                                    <td>{client.email}</td>
                                    <td>{client.pageSource || 'lets talk'}</td>
                                    <td>{new Date(client.createdAt).toLocaleString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </main>
            {isModalActive && selectedClient && (
                <div className="ps-modal-bg active">
                    <div className="ps-modal">
                        <section className="psm-side1">
                            <h3>Email</h3>
                            <h3>Country</h3>
                            <h3>Service</h3>
                            <h3>Message</h3>
                        </section>
                        <section className="psm-side2">
                            <div className="pms-subheading">
                                <h2>{selectedClient.pageSource || 'lets talk'}</h2>
                                <span className="pms-cancel" onClick={handleCloseModal}>X</span>
                            </div>
                            <div className="pst-details">
                                <div>{selectedClient.email}</div>
                                <div>{selectedClient.country}</div>
                                <div>
                                    <span>{selectedClient.service}</span>
                                </div>
                                <div>
                                    <textarea defaultValue={selectedClient.additionalInformation || 'no info'} readOnly></textarea>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}
        </div>
    );
}
